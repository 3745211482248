import { CardHeader, Paper, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelReceiver,
  getReceivers,
  receiversSelector,
  messageStatsSelector,
  selReceiverSelector,
  selRecFeaturesSelector,
  getMessagesForUser,
  getRemindersForReceiver,
  getRecordings,
  setSelRecFeatures,
  focusedMessageIdSelector,
  getCustomToken,
  customTokenSelector,
} from './messageSlice';
import moment from 'moment';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Recordings from './recordings';
import Reminders from './reminders';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CustomerServiceForm from './customerService';
import { Autocomplete } from '@mui/lab';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';
import SentimentDashboard from './sentiment/SentimentDashboard';
import MessageList from './MessageList';
import { MasterFeatureTypeRef } from '../../utils/account';
import { SentimentAnalysisPlatform } from '../../utils/sentiment';
import PortalIconButton from '../shared/components/PortalIconButton';
import PortalTab from '../shared/components/PortalTab';
import { NavLink } from 'react-router-dom';
import { MessageStatList } from '../home/MessageStatList';
import { getDatabase, onValue, ref, set, update } from 'firebase/database';
import { portalUserSelector } from '../../app/authSlice';
import { useLocation } from 'react-router-dom';

const PREFIX = 'Messaging';

const classes = {
  root: `${PREFIX}-root`,
  receiversRoot: `${PREFIX}-receiversRoot`,
  tab: `${PREFIX}-tab`,
  tabLabel: `${PREFIX}-tabLabel`,
  tabPanelHolder: `${PREFIX}-tabPanelHolder`,
  dropdown: `${PREFIX}-dropdown`,
  paper: `${PREFIX}-paper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  headHolder: `${PREFIX}-headHolder`,
  cardHeader: `${PREFIX}-cardHeader`,
};

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      minWidth: 100,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),
    },

    [`& .${classes.receiversRoot}`]: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    },

    [`& .${classes.tab}`]: {
      flexGrow: theme.spacing(1),
      margin: theme.spacing(0),
    },

    [`& .${classes.tabLabel}`]: {
      color: '#fff',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    [`& .${classes.tabLabel}.selected`]: {
      color: '#fff',
    },
    [`& .${classes.tabPanelHolder}`]: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1),
    },

    [`& .${classes.dropdown}`]: {
      alignContent: 'center',
      color: theme.palette.primary.main,
      whiteSpace: 'pre-wrap',
    },

    [`& .${classes.paper}`]: {
      margin: theme.spacing(1),
    },

    [`& .${classes.card}`]: {
      marginTop: '26px',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      borderRadius: theme.spacing(2),
    },

    [`& .${classes.cardContent}`]: {
      margin: 0,
      padding: 0,
    },

    [`& .${classes.headHolder}`]: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    [`& .${classes.cardHeader}`]: {
      margin: 0,
      padding: theme.spacing(2),
      color: theme.palette.primary.main,
      alignContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      background: '#f7f7f7',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
});

const tabIdentifier = {
  message: 'message',
  recording: 'recording',
  customer_service: 'customer_service',
  reminder: 'reminder',
  sentiment: 'sentiment',
};

const tabOptions = {
  messages: {
    identifier: tabIdentifier.message,
    label: 'Messages',
    dataTour: 'rtMessages',
    tabPanelComponent: MessageList,
    componentProps: {},
    displayOrder: 0,
    style: { margin: 10 },
  },
  recordings: {
    identifier: tabIdentifier.recording,
    label: 'Recordings',
    dataTour: 'rtRecordings',
    tabPanelComponent: Recordings,
    componentProps: {},
    displayOrder: 1,
  },
  customerService: {
    identifier: tabIdentifier.customer_service,
    label: 'Customer Service',
    dataTour: 'rtRecCustSrvc',
    tabPanelComponent: CustomerServiceForm,
    componentProps: { type: 'receiver' },
    displayOrder: 2,
  },
  reminders: {
    identifier: tabIdentifier.reminder,
    label: 'Reminders',
    dataTour: 'rtReminders',
    tabPanelComponent: Reminders,
    componentProps: {},
    displayOrder: 3,
  },
  sentiment: {
    identifier: tabIdentifier.sentiment,
    label: 'Sentiment',
    dataTour: 'rtRecSenti',
    tabPanelComponent: SentimentDashboard,
    componentProps: {
      sentimentAnalysisPlatform: SentimentAnalysisPlatform.assembly_ai_sentiment_analysis,
    },
    displayOrder: 4,
  },
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      aria-labelledby={`messaging-tab-${index}`}
      hidden={value !== index}
      id={`messaging-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Paper
          style={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          {children}
        </Paper>
      )}
    </div>
  );
};

const tourStepsOptions = {
  selectReceiver: {
    selector: '[data-tour="rtSelRecv"]',
    content: 'Use this down arrow to see messages on different receivers.',
    displayOrder: 0,
  },
  escalations: {
    selector: '[data-tour="rtescalations"]',
    content:
      'Based on the receiver selected, this is the number of pending escalations shown in the message query below.',
    displayOrder: 1,
  },
  totalMessages: {
    selector: '[data-tour="rttotal"]',
    content:
      'Based on the receiver selected, this is the total number of messages shown in the message query below.',
    displayOrder: 2,
  },
  followUp: {
    selector: '[data-tour="rtfollowup"]',
    content:
      'Based on the receiver selected, this is the number of messages flagged for followup shown in the message query below.',
    displayOrder: 3,
  },
  messages: {
    selector: '[data-tour="rtMessages"]',
    content:
      'Message List.  The default is to show the most recent 10 message from the last 31 days. ',
    displayOrder: 4,
  },
  recordings: {
    selector: '[data-tour="rtRecordings"]',
    position: 'bottom',
    content: 'To view all recordings.',
    displayOrder: 5,
  },
  customerService: {
    selector: '[data-tour="rtRecCustSrvc"]',
    content:
      'A way to contact customer service via a web form.  You can request communication via email or phone.',
    displayOrder: 6,
  },
  reminders: {
    selector: '[data-tour="rtReminders"]',
    content: 'Create and maintain single or recurring reminders to send to the selected receiver.',
    displayOrder: 7,
  },
  sentiment: {
    selector: '[data-tour="rtRecSenti"]',
    content: 'View sentiment analysis of messages.',
    displayOrder: 8,
  },
};

const Messaging = () => {
  const dispatch = useDispatch();
  const disabledFeatures = useSelector(selRecFeaturesSelector);
  const selReceiver = useSelector(selReceiverSelector);
  const receivers = useSelector(receiversSelector);
  const messageStats = useSelector(messageStatsSelector);
  const [acReceiver, setAcReceiver] = useState('');
  const [tabsToRender, setTabsToRender] = useState([]);
  const [tourSteps, setTourSteps] = useState([]);
  const [openTour, setOpenTour] = useState(false);
  const [tourCurrentStep, setTourCurrentStep] = useState(0);
  const [value, setValue] = useState(0);
  const [messageWorkStatus, setMessageWorkStatus] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const [styleType, setStyleType] = useState(null);
  const [isWindowActive, setIsWindowActive] = useState(true);
  const portalUser = useSelector(portalUserSelector);
  const focusedMessageId = useSelector(focusedMessageIdSelector);
  const componentRef = useRef(null);
  const customToken = useSelector(customTokenSelector);
  useEffect(() => {
    if (componentRef.current) {
      // Check if the component is focused
      const isFocused = document.activeElement === componentRef.current;

      if (isFocused) {
        // Perform actions when the component is focused
        // console.log('Component is focused');
      } else {
        //console.log('Component is unfocused');
      }
    }
  }, [location]);

  useEffect(() => {
    if (!styleType) {
      setStyleType('theme_color');
    }
  }, []);

  const closeTour = () => {
    setOpenTour(false);
  };

  const startTour = (value) => {
    setOpenTour(true);
  };

  const [serviceNum, setServiceNum] = useState(
    localStorage.getItem('lastSelReceiver') ? localStorage.getItem('lastSelReceiver') : '0',
  );
  const [serviceName, setServiceName] = useState(
    localStorage.getItem('lastSelReceiverName') ? localStorage.getItem('lastSelReceiverName') : '',
  );
  // console.log('focusedmessageId', focusedMessageId);

  useEffect(() => {
    // console.log('isWindowActive', isWindowActive);
    // console.log('selReceiver', selReceiver);
    // console.log('focusedMessageid', focusedMessageId);
    // console.log('portaluser', portalUser.id);
    if (focusedMessageId !== '' && portalUser && portalUser.id && selReceiver) {
      updateReceiverChanges(
        selReceiver,
        portalUser,
        focusedMessageId,
        isWindowActive ? 'active' : 'inactive',
      );
    }
  }, [isWindowActive, focusedMessageId, selReceiver]);

  // useEffect(() => {
  //   console.log("isWindowActive", isWindowActive);
  //   console.log('selReceiver', selReceiver);
  //   console.log('focusedMessageid', focusedMessageId);
  //   console.log('portaluser', portalUser.id)
  //   if (focusedMessageId !== '' && portalUser && portalUser.id && selReceiver) {
  //     updateReceiverChanges(selReceiver, portalUser, focusedMessageId, IsFocused ? 'active' : 'inactive');
  //   }
  // }, [IsFocused, focusedMessageId, selReceiver])

  useEffect(() => {
    const handleVisibilityChange = () => {
      // console.log('document visibility', document.visibilityState);
      // console.log('document visibility messageId', focusedMessageId);
      setIsWindowActive(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const updateReceiverChanges = (receiver, webuser, messageId, status) => {
    const db = getDatabase();
    //console.log("workupdate path", `receivers/${receiver}/messages/${messageId}/workUpdates/`)
    const path = ref(db, `receivers/${receiver}/messages/${messageId}/workUpdates/`);
    // console.log("path check", path)
    update(path, {
      webuserId: webuser.id,
      loginTxt: webuser.login,
      updatedTime: new Date().toUTCString(),
      status: status,
    });
  };

  useEffect(() => {
    if (selReceiver !== 0) {
      dispatch(setSelReceiver(selReceiver));
      var rcvrs = receivers.filter((element) => element.number === selReceiver);
      if (rcvrs.length === 1) {
        var disabledFeatures = [];

        rcvrs[0].features.forEach((item) => {
          if (item.status === 'DISABLE') {
            if (item.masterFeature.featureTypeRef !== 'WBMSGRSTCT') {
              disabledFeatures.push(item.masterFeature.featureTypeRef);
            }
          } else {
            //default it to all acq companies
            if (item.masterFeature.featureTypeRef === 'WBMSGRSTCT') {
              disabledFeatures.push(item.masterFeature.featureTypeRef);
            }
          }
        });

        /** 
         * Portal Message Restriction  for Medical customers when this  feature is enabled  
            o	 Message Forward will be disabled 
            o	 Message Download will be enabled
         * If feature is disabled, both forward and download is enabled
         * If feature is missing, both forward and download is enabled
         * So based on above, Downloading msgs is default for everyone
         * signed by SDK
         */

        var verifyDownlable = rcvrs[0].features.filter(
          (element) => element.masterFeature.featureTypeRef === 'RCRDDWNLD',
        );
        if (verifyDownlable.length === 0) {
          disabledFeatures.push('RCRDDWNLD');
        }

        var verifyRecordsTab = rcvrs[0].features.filter(
          (element) => element.masterFeature.featureTypeRef === 'WEBRECORD',
        );
        if (verifyRecordsTab.length === 0) {
          disabledFeatures.push('WEBRECORD');
        }

        var verifyArchive = rcvrs[0].features.filter(
          (element) => element.masterFeature.featureTypeRef === 'FUNERAL',
        );
        if (verifyArchive.length === 0) {
          disabledFeatures.push('FUNERAL');
        }

        var verifyInlineRecordings = rcvrs[0].features.filter(
          (element) => element.masterFeature.featureTypeRef === 'INLINERCRD',
        );
        if (verifyInlineRecordings.length === 0) {
          disabledFeatures.push('INLINERCRD');
        }

        var sentimentTab = rcvrs[0].features.filter(
          (element) => element.masterFeature.featureTypeRef === MasterFeatureTypeRef.SENTIMENT,
        );
        if (sentimentTab.length === 0) {
          disabledFeatures.push(MasterFeatureTypeRef.SENTIMENT);
        }
        dispatch(setSelRecFeatures(disabledFeatures));
      }

      if (receivers.length !== 0 && receivers[0].number === selReceiver) {
        setServiceName(receivers[0].holderTxt);
        setServiceNum(receivers[0].number);
      }
    }
  }, [dispatch, selReceiver]);

  /**
   * sets the tabs to render based on disabledFeatures
   * resets the tour current step to 0 on receiver change
   * */
  useEffect(() => {
    reAssessTabOptions();
  }, [disabledFeatures]);

  const reAssessTabOptions = () => {
    setTourCurrentStep(0);
    formulateTabsRendering();
  };

  /**
   *  renders to Messages tab if current location path is not allowed to display on receiver change
   * */
  useEffect(() => {
    if (
      tabsToRender &&
      tabsToRender?.length > 0 &&
      tabsToRender.findIndex((tab) => tab.displayOrder === value) === -1
    ) {
      handleTabChange(tabOptions.messages, 0);
    }
  }, [tabsToRender]);

  useEffect(() => {
    if (!receivers || receivers.length === 0) {
      dispatch(getReceivers());
    }
  }, [dispatch]);

  useEffect(() => {
    if (receivers.length > 0) {
      receivers.forEach((element) => {
        if (selReceiver === element.number) {
          setAcReceiver(element);
          reAssessTabOptions();
        }
      });
    }
  }, [receivers.length, selReceiver]);

  const handleTourStepChange = (currentStep) => {
    setTourCurrentStep(currentStep);
  };

  const handleTabChange = (selectedTab, newValue) => {
    setValue(newValue);
    switch (selectedTab.identifier) {
      case tabIdentifier.message:
        fetchMessages(selReceiver);
        break;
      case tabIdentifier.reminder:
        fetchReminders(selReceiver);
        break;
      case tabIdentifier.recording:
        fetchRecordings(selReceiver);
        break;
      default:
        break;
    }
  };

  const fetchMessages = (receiverId) => {
    console.log(`fetch message received${receiverId}`);
    dispatch(
      getMessagesForUser(
        receiverId,
        moment.utc().subtract(7, 'd').format('yyyyMMDDHHmmss'),
        moment.utc().format('yyyyMMDDHHmmss'),
      ),
    );
    //updateReceiverChange(receiverId)
  };
  const fetchReminders = (receiverId) => {
    dispatch(getRemindersForReceiver(receiverId));
  };

  const fetchRecordings = (receiverId) => {
    dispatch(
      getRecordings(
        receiverId,
        moment.utc().subtract(1, 'd').startOf('day').format('DD-MM-YY HH:mm:ss'),
        moment.utc().endOf('day').format('DD-MM-YY HH:mm:ss'),
      ),
    );
  };

  const handleReceiverChange = (item) => {
    if (item != null) {
      const value = item.number;
      setServiceName(item.holderTxt);
      setServiceNum(value);
      dispatch(setSelReceiver(value));

      fetchReminders(value);
      fetchRecordings(value);
      if (
        !sessionStorage.getItem('xauth') ||
        (sessionStorage.getItem('xauth') && sessionStorage.getItem('xauth')?.length === 0)
      ) {
        localStorage.setItem('lastSelReceiver', value);
        localStorage.setItem('lastSelReceiverName', item.holderTxt);
      }
    }
  };

  const formulateTabsRendering = () => {
    let tabsToRender = [tabOptions.messages, tabOptions.customerService];
    let tourStepsToRender = [
      tourStepsOptions.selectReceiver,
      tourStepsOptions.escalations,
      tourStepsOptions.totalMessages,
      tourStepsOptions.followUp,
      tourStepsOptions.messages,
      tourStepsOptions.customerService,
    ];
    if (disabledFeatures && disabledFeatures.indexOf('WEBRECORD') === -1) {
      tabsToRender = [...tabsToRender, tabOptions.recordings];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.recordings];
    }
    if (displayRemindersTab()) {
      tabsToRender = [...tabsToRender, tabOptions.reminders];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.reminders];
    }
    if (disabledFeatures && disabledFeatures.indexOf(MasterFeatureTypeRef.SENTIMENT) === -1) {
      tabsToRender = [...tabsToRender, tabOptions.sentiment];
      tourStepsToRender = [...tourStepsToRender, tourStepsOptions.sentiment];
    }
    setTabsToRender(tabsToRender.sort((tab1, tab2) => tab1.displayOrder - tab2.displayOrder));
    setTourSteps(tourStepsToRender.sort((step1, step2) => step1.displayOrder - step2.displayOrder));
    console.log(selReceiver, disabledFeatures);
  };

  const displayRemindersTab = () => {
    return (
      receivers.filter(
        (element) =>
          element.number === selReceiver &&
          element.subTypeRef !== 'MGPGR' &&
          element.subTypeRef !== 'MSDUM' &&
          element.subTypeRef !== 'MSEML',
      ).length > 0
    );
  };

  return (
    <StyledGrid className={classes.root} container>
      <Grid item xs={12}>
        <PortalIconButton onClick={startTour} style={{ position: 'absolute', top: 68, right: 20 }}>
          <LiveHelpIcon />
        </PortalIconButton>
        <Card className={classes.card}>
          <CardHeader
            avatar={<PhoneCallbackIcon />}
            className={classes.cardHeader}
            title={
              <Grid className={classes.headHolder} container spacing={1}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  {receivers && (
                    <Autocomplete
                      data-tour="rtSelRecv"
                      fullWidth
                      getOptionLabel={(option) =>
                        option === ''
                          ? !!serviceNum && !!serviceName
                            ? `${serviceNum} - ${serviceName}`
                            : 'No Receiver Affiliated'
                          : option.number +
                            (option.nickName
                              ? ` - ${option?.nickName?.toUpperCase()}`
                              : option.holderTxt
                                ? ` - ${option.holderTxt}`
                                : '')
                      }
                      id="select-receiver-autocomplete"
                      onChange={(event, newValue) => {
                        handleReceiverChange(newValue);
                        setAcReceiver(newValue);
                      }}
                      options={
                        receivers && receivers?.length > 0
                          ? receivers
                              .slice()
                              .sort((a, b) =>
                                a.nickName
                                  ? a.nickName.localeCompare(b.nickName ? b.nickName : b.holderTxt)
                                  : a.holderTxt
                                    ? a.holderTxt.localeCompare(
                                        b.nickName ? b.nickName : b.holderTxt,
                                      )
                                    : ''.localeCompare(b.nickName ? b.nickName : b.holderTxt),
                              )
                          : []
                      }
                      renderInput={(params) => {
                        if (params) {
                          return (
                            <TextField
                              {...params}
                              label={t('Select Receiver')}
                              variant="outlined"
                            />
                          );
                        }
                        return null;
                      }}
                      style={{ flex: 1, marginRight: '8px', background: 'white' }}
                      value={acReceiver}
                    />
                  )}
                </Grid>

                {messageStats && (
                  <MessageStatList
                    gridSize={2}
                    messageStats={messageStats}
                    messageStatsOptions={['total', 'escalations', 'followup']}
                  />
                )}
              </Grid>
            }
          />
        </Card>
      </Grid>

      <Grid className={classes.tabPanelHolder} item xs={12}>
        <Tour
          disableFocusLock
          getCurrentStep={handleTourStepChange}
          isOpen={openTour}
          onRequestClose={closeTour}
          startAt={tourCurrentStep}
          steps={tourSteps}
        />
        <AppBar position="static">
          <Tabs
            aria-label="messaging tabs"
            indicatorColor="secondary"
            scrollButtons="auto"
            textColor="primary"
            value={value}
            variant="scrollable"
          >
            {tabsToRender?.map((tab, index) => (
              <PortalTab
                className={classes.tabLabel}
                component={NavLink}
                data-tour={tab.dataTour}
                key={index}
                label={t(tab.label)}
                onClick={() => handleTabChange(tab, index)}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabsToRender?.map((tab, index) => (
          <TabPanel className={classes.tab} index={index} key={index} value={value}>
            <tab.tabPanelComponent {...tab.componentProps} />
          </TabPanel>
        ))}
      </Grid>
    </StyledGrid>
  );
};

export default Messaging;
