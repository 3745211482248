import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import {
  ivrMessagesSelector,
  selectedVoicemailMailboxSelector,
  voicemailSearchTextSelector,
  voicemailSortKeySelector,
  voicemailSortOrderSelector,
} from './voicemailSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import IvrMessage from './IvrMessage';
import { useTranslation } from 'react-i18next';
import IvrMessageFilter from './IvrMessageFilter';
import { getMailboxDropdownOption } from '../../../utils/ivrMessage';
import { timezoneSelector } from '../../../app/authSlice';

const PREFIX = 'VoicemailsByLineNumber';

export default function VoicemailsByLineNumber() {
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();
  const ivrMessagesSel = useSelector(ivrMessagesSelector);
  const sortKey = useSelector(voicemailSortKeySelector);
  const sortOrder = useSelector(voicemailSortOrderSelector);
  const selectedVoicemailBox = useSelector(selectedVoicemailMailboxSelector);
  const voicemailSearchText = useSelector(voicemailSearchTextSelector);
  const defaultTimezone = useSelector(timezoneSelector);

  useEffect(() => {
    setMessages(ivrMessagesSel);
  }, [ivrMessagesSel]);

  return (
    <>
      {messages && messages.length > 0 && <IvrMessageFilter />}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
        {messages
          .slice()
          .filter((element) =>
            voicemailSearchText != ''
              ? element?.transcribedMessage
                  ?.toLowerCase()
                  ?.includes(voicemailSearchText?.toLowerCase())
              : true,
          )
          .filter((element) =>
            selectedVoicemailBox != 'all'
              ? getMailboxDropdownOption(element)?.includes(selectedVoicemailBox)
              : true,
          )
          .sort((a, b) => {
            let valueA = a[sortKey];
            let valueB = b[sortKey];
            if (sortKey === 'mailboxNumber') {
              valueA = a?.mailboxDetail?.mailboxNumber;
              valueB = b?.mailboxDetail?.mailboxNumber;
            }
            if (sortOrder === 'asc') {
              return valueA > valueB ? 1 : -1;
            } else {
              return valueB > valueA ? 1 : -1;
            }
          })
          .map((eachMessage, index) => (
            <IvrMessage defaultTimezone={defaultTimezone} ivrMessage={eachMessage} key={index} />
          ))}
      </Box>
      {messages && messages.length === 0 && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="overline">{t('no records found')}</Typography>
        </Box>
      )}
    </>
  );
}
