import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from '@mui/material';
import moment from 'moment-timezone';

import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteVoicemailById,
  getIvrMessageBlobById,
  voicemailGridDisplayTypeSelector,
  voicemailSearchTextSelector,
} from './voicemailSlice';
import PortalButton from '../../shared/components/PortalButton';
import PortalHighlightedText from '../../shared/components/PortalHighlightedText';
import PortalAudioPlayer from '../../shared/components/PortalAudioPlayer';
import { saveAs } from 'file-saver';
import PortalDialog from '../../shared/components/PortalDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const PREFIX = 'IvrMessage';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardTop: `${PREFIX}-cardTop`,
  cardBottom: `${PREFIX}-cardBottom`,
  cardBottomHeader: `${PREFIX}-cardBottomHeader`,
  item: `${PREFIX}-item`,
  msgBody: `${PREFIX}-msgBody`,
  divider: `${PREFIX}-divider`,
  playAndDownloadButton: `${PREFIX}-playAndDownloadButton`,
};

const StyleGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    transition: 'all .1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(1),
  },

  [`& .${classes.card}`]: {
    flexGrow: 1,
    padding: 0,
    borderRadius: '16px',
    boxShadow:
      '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 11%)',
    '&:hover': {
      boxShadow:
        '0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 0px 2px rgb(0 172 193 / 51%)',
    },
  },

  [`& .${classes.grid}`]: {
    padding: 0,
  },

  [`& .${classes.cardTop}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: '#01adc200',
    padding: theme.spacing(1),
  },

  [`& .${classes.cardBottom}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: 'white',
  },

  [`& .${classes.cardBottomHeader}`]: {
    display: 'flex',
    flexGrow: 1,
    fontStyle: 'italic',
    alignContent: 'baseline',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '4px',
  },

  [`& .${classes.actionsHolder}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '#f9f9f97a',
    justifyContent: 'space-between',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: '#f9f9f97a',
    color: 'black',
  },

  [`& .${classes.item}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  [`& .${classes.msgBody}`]: {
    fontSize: 18,
    wordBreak: 'break-word',
    padding: theme.spacing(1),
  },
  [`& .${classes.divider}`]: {
    marginRight: '8px',
    marginLeft: '8px',
  },
  [`& .${classes.playAndDownloadButton}`]: {
    borderRadius: '5px',
    padding: '1px 30px',
  },
}));

export default function IvrMessage({ ivrMessage, defaultTimezone }) {
  const dispatch = useDispatch();
  const [audioUrl, setAudioUrl] = useState(null);
  const [openDeleteVoicemailConfirmationDialog, setOpenDeleteVoicemailConfirmationDialog] =
    useState(false);
  const isDownloading = useRef(false);
  const displayType = useSelector(voicemailGridDisplayTypeSelector);
  const voicemailSearchText = useSelector(voicemailSearchTextSelector);

  const getIvrMessageDescription = () => {
    return `${ivrMessage?.lineNumber} | Mailbox: ${ivrMessage?.mailboxDetail?.mailboxNumber} - ${ivrMessage?.mailboxDetail?.mailboxHolderName}`;
  };

  const handlePlayOrDownloadAudio = (actionType) => {
    if (ivrMessage?.id && !isDownloading.current) {
      isDownloading.current = true;
      dispatch(
        getIvrMessageBlobById(ivrMessage?.id, (blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            setAudioUrl(url);
            if (actionType === 'download') {
              saveAs(blob, `${ivrMessage?.id}.wav`);
            }
          }
          isDownloading.current = false;
        }),
      );
      return () => {
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
        }
      };
    }
  };

  const handleDeleteVoicemail = () => {
    if (ivrMessage?.id) {
      setOpenDeleteVoicemailConfirmationDialog(true);
    }
  };

  const confirmVoicemailDeletion = () => {
    if (ivrMessage?.id) {
      dispatch(deleteVoicemailById(ivrMessage?.id));
      closeVoicemailDeletionDialog();
    }
  };

  const closeVoicemailDeletionDialog = () => {
    setOpenDeleteVoicemailConfirmationDialog(false);
  };

  const DeleteVoicemailConfirmationComponent = () => {
    return (
      <div>
        <p> {getIvrMessageDescription()}</p>
        <p>Are you sure you want to delete this voicemail?</p>
      </div>
    );
  };

  /**
   *
   * @param  date
   * @returns
   * Note: In Moment.js, months are zero-indexed (i.e., January is 0, February is 1, ..., and December is 11).
   */
  const getDateObject = (date) => {
    try {
      return {
        year: date[0],
        month: date[1] - 1,
        day: date[2],
        hour: date[3],
        minute: date[4],
        second: date[5],
      };
    } catch (error) {
      console.error('Error ', error);
      return date;
    }
  };
  const TranscriptionBox = ({ title, text, defaultText, defaultShow, textFontStyle }) => {
    const [show, setShow] = useState(defaultShow);

    const handleClick = () => {
      setShow(!show);
    };

    return (
      <Box>
        <Typography
          component="span"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '0 !important',
          }}
        >
          {text ? (
            <Button
              color="primary"
              onClick={handleClick}
              sx={{ textTransform: 'none' }}
              variant="text"
            >
              <span
                style={{
                  fontWeight: '600',
                  fontStyle: 'normal',
                }}
              >
                {title}
              </span>{' '}
              {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}{' '}
            </Button>
          ) : null}
        </Typography>

        {show && (
          <Typography
            component="p"
            sx={{
              fontSize: '16px',
              opacity: text ? '1' : '0.4',
              fontStyle: textFontStyle,
              padding: '5px 5px 10px 5px',
              textAlign: 'justify',
            }}
            variant={text ? 'body1' : 'string'}
          >
            <PortalHighlightedText pattern={voicemailSearchText} text={text || defaultText} />
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <StyleGrid className={classes.root} item md={displayType === 'grid' ? 3 : 12}>
      <Card className={classes.card}>
        <CardHeader
          subheader={
            <Typography variant="subtitle2">
              {moment
                .utc(getDateObject(ivrMessage?.creationDate))
                .tz(defaultTimezone ? defaultTimezone : 'America/New_York')
                .format('MMM DD, YYYY hh:mm A z')}
            </Typography>
          }
          sx={{ textAlign: displayType === 'grid' ? 'center' : 'left' }}
          title={<Typography variant="caption">{getIvrMessageDescription()}</Typography>}
        />
        <CardContent sx={{ textAlign: 'center', padding: '0' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <PortalButton
              className={classes.playAndDownloadButton}
              color="primary"
              disabled={!!audioUrl}
              onClick={() => handlePlayOrDownloadAudio('play')}
              size={displayType === 'grid' ? 'small' : 'large'}
              variant="contained"
            >
              Play
            </PortalButton>
            <PortalButton
              className={classes.playAndDownloadButton}
              color="secondary"
              onClick={() => handlePlayOrDownloadAudio('download')}
              size={displayType === 'grid' ? 'small' : 'large'}
              variant="contained"
            >
              Download
            </PortalButton>

            <PortalButton
              className={classes.playAndDownloadButton}
              color="error"
              onClick={handleDeleteVoicemail}
              size={displayType === 'grid' ? 'small' : 'large'}
              variant="contained"
            >
              Delete
            </PortalButton>
          </Box>
        </CardContent>
        <CardActions sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {audioUrl ? <PortalAudioPlayer audioUrl={audioUrl} style={{ width: '100%' }} /> : null}

          {ivrMessage?.transcribedMessageSummary ? (
            <TranscriptionBox
              defaultShow={true}
              text={ivrMessage?.transcribedMessageSummary}
              textFontStyle="italic"
              title="Voicemail Summary"
            />
          ) : null}
          <TranscriptionBox
            defaultShow={!ivrMessage?.transcribedMessageSummary}
            defaultText="Voicemail transcription is not available."
            text={ivrMessage?.transcribedMessage}
            textFontStyle="normal"
            title="Actual Voicemail"
          />
        </CardActions>
        {isDownloading.current && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Card>
      {openDeleteVoicemailConfirmationDialog && ivrMessage?.id && (
        <PortalDialog
          ComponentToRender={DeleteVoicemailConfirmationComponent}
          actionItems={[
            {
              autoFocus: true,
              onClick: confirmVoicemailDeletion,
              color: 'error',
              text: 'Delete',
              variant: 'contained',
            },
            {
              autoFocus: false,
              onClick: closeVoicemailDeletionDialog,
              color: 'secondary',
              text: 'Cancel',
              variant: 'contained',
            },
          ]}
          fullScreen={false}
          open={openDeleteVoicemailConfirmationDialog}
          propagateOnClose={closeVoicemailDeletionDialog}
          showAppBar={false}
          size="md"
          title="Delete voicemail"
        />
      )}
    </StyleGrid>
  );
}
