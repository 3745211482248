import { createSlice } from '@reduxjs/toolkit';
import * as PortalApi from '../../../PortalApi';
import { setPortalSnackInfo } from '../../../app/authSlice';
import { setCustomizedProgressLoading } from '../../../slices/customizedProgressLoaderSlice';

export const voicemailSlice = createSlice({
  name: 'voicemail',
  initialState: {
    ivrMessages: [],
    selectedVoicemailLineNumber: null,
    voicemailSearchText: '',
    selectedVoicemailMailbox: 'all',
    voicemailGridDisplayType: 'grid',
    voicemailSortKey: 'creationDate',
    voicemailSortOrder: 'desc',
    refetchVoicemails: false,
  },
  reducers: {
    setIvrMessages: (state, action) => {
      state.ivrMessages = action.payload;
    },
    setSelectedVoicemailLineNumber: (state, action) => {
      state.selectedVoicemailLineNumber = action.payload;
    },
    setVoicemailSearchText: (state, action) => {
      state.voicemailSearchText = action.payload;
    },
    setVoicemailGridDisplayType: (state, action) => {
      state.voicemailGridDisplayType = action.payload;
    },
    setSelectedVoicemailMailbox: (state, action) => {
      state.selectedVoicemailMailbox = action.payload;
    },
    setVoicemailSortKey: (state, action) => {
      state.voicemailSortKey = action.payload;
    },
    setVoicemailSortOrder: (state, action) => {
      state.voicemailSortOrder = action.payload;
    },
    setRefetchVoicemails: (state, action) => {
      state.refetchVoicemails = action.payload;
    },
  },
});

export const {
  setIvrMessages,
  setSelectedVoicemailLineNumber,
  setVoicemailGridDisplayType,
  setSelectedVoicemailMailbox,
  setVoicemailSortKey,
  setVoicemailSortOrder,
  setVoicemailSearchText,
  setRefetchVoicemails,
} = voicemailSlice.actions;

export const ivrMessagesSelector = (state) => state.voicemail.ivrMessages;
export const selectedVoicemailLineNumberSelector = (state) =>
  state.voicemail.selectedVoicemailLineNumber;
export const voicemailGridDisplayTypeSelector = (state) => state.voicemail.voicemailGridDisplayType;
export const selectedVoicemailMailboxSelector = (state) => state.voicemail.selectedVoicemailMailbox;
export const voicemailSortKeySelector = (state) => state.voicemail.voicemailSortKey;
export const voicemailSortOrderSelector = (state) => state.voicemail.voicemailSortOrder;
export const voicemailSearchTextSelector = (state) => state.voicemail.voicemailSearchText;
export const refetchVoicemailsSelector = (state) => state.voicemail.refetchVoicemails;

export function getIvrMessages(lineNumber, startDate, endDate) {
  return (dispatch) => {
    try {
      dispatch(setCustomizedProgressLoading(true));
      PortalApi.call(
        `/ivrmessage/${lineNumber}?startDate=${encodeURIComponent(
          startDate,
        )}&endDate=${encodeURIComponent(endDate)}`,
        { method: 'get' },
        (response) => {
          dispatch(setIvrMessages(response));
          dispatch(setCustomizedProgressLoading(false));
        },
        (error, status, content) => {
          console.log(error, status, content);
          dispatch(setCustomizedProgressLoading(false));
        },
      );
    } catch (error) {
      dispatch(setCustomizedProgressLoading(false));
    }
  };
}

export function getIvrMessageBlobById(id, callback) {
  return (dispatch) => {
    try {
      PortalApi.call(
        `/ivrmessage/${id}/blob`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
        (response) => {
          if (callback) callback(response);
        },
        (error, status, content) => {
          console.log(error, status, content);
          if (callback) callback(null);
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: 'Oops, something went wrong while downloading audio.',
            }),
          );
        },
      );
    } catch (error) {
      console.error('Error', error);
    }
  };
}

export function deleteVoicemailById(id) {
  return (dispatch) => {
    try {
      PortalApi.call(
        `/ivrmessage/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response) => {
          dispatch(
            setPortalSnackInfo({
              severity: 'success',
              message: 'Voicemail is deleted successfully!.',
            }),
          );
          dispatch(setRefetchVoicemails(true));
        },
        (error, status, content) => {
          console.log(error, status, content);
          dispatch(
            setPortalSnackInfo({
              severity: 'error',
              message: 'Oops, something went wrong while deleting voicemail. Please try again.',
            }),
          );
        },
      );
    } catch (error) {
      console.error('Error', error);
    }
  };
}

export default voicemailSlice.reducer;
