import { useEffect } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import Router from './Router';
import { useSelector, useDispatch } from 'react-redux';
import { fcmTokenSelector, setFcmToken } from './app/authSlice';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import getTheme from './theme';
import { portalUserSelector } from './app/authSlice';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

// const firebaseConfig = {
//   apiKey: 'AIzaSyDSN-LIk3Ky-i7DQBzAY7MChQV25UG375Y',
//   authDomain: 'cognisent-2.firebaseapp.com',
//   databaseURL: 'https://cognisent-2-default-rtdb.firebaseio.com',
//   projectId: 'cognisent-2',
//   storageBucket: 'cognisent-2.appspot.com',
//   messagingSenderId: '762052541154',
//   appId: '1:762052541154:web:0757ef9972325800893cbb',
//   measurementId: 'G-DX25559CP7',
// };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyByA9tSpESY2Tu7HGU1KiGwofd7Y8__9nU',
  authDomain: 'map-customer-portal.firebaseapp.com',
  databaseURL: 'https://map-customer-portal-default-rtdb.firebaseio.com',
  projectId: 'map-customer-portal',
  storageBucket: 'map-customer-portal.appspot.com',
  messagingSenderId: '989136367781',
  appId: '1:989136367781:web:2f7601cfda1f796ff51d4c',
  measurementId: 'G-1Y4W757E44',
};

let messaging = '';

export default function App() {
  const dispatch = useDispatch();
  const portalUser = useSelector(portalUserSelector);
  const { isAuthenticated } = useAuth0();
  const fcmToken = useSelector(fcmTokenSelector);

  useEffect(() => {
    if (window.location.host != 'portal-v2.mapcom.local') {
      initializeFirebase();
    }
  }, [dispatch]);

  useEffect(() => {
    if (messaging && window.location.host != 'portal-v2.mapcom.local') {
      // TOD: update this logic to allow only prod
      processToken();
    }
  }, [messaging]);

  const initializeFirebase = async () => {
    const initialize = await isSupported();
    if (initialize) {
      const fapp = initializeApp(firebaseConfig);
      messaging = getMessaging(fapp);
    }
  };

  const processToken = () => {
    getToken(messaging, {
      vapidKey:
        'BIgnOC1OvvEbMRz07X0w7anx6fV-BfZ84zlAHHO1FjgXnedGks6pXTO9jxdfrP2NN3sK9XUyOjvlAcItbL-loOE',
    })
      .then((currentToken) => {
        if (currentToken) {
          dispatch(setFcmToken(currentToken));
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  /** this was work in progress for notification but currently do not possess any implemented. Thus, commented */
  /*
  useEffect(() => {
    if (isAuthenticated && fcmToken && fcmToken.length > 0 && portalUser && portalUser.id) {
      dispatch(saveFCMToken({ token: fcmToken, device: 'Browser', type: 'WEB', appName: 'PORTAL' }));
    }
  }, [dispatch, isAuthenticated, fcmToken, portalUser]);

  onMessage(messaging, payload => {
    if (payload && payload.body && payload.body === 'On call schedule summary') {
      dispatch(setPortalSnackInfo({ severity: 'success', message: 'Your file is ready to download' }));
    }
  });
  */

  return (
    <div>
      <ThemeProvider
        theme={
          portalUser && portalUser.optionsJson
            ? getTheme(JSON.parse(portalUser.optionsJson).theme)
            : theme
        }
      >
        <Router />
      </ThemeProvider>
    </div>
  );
}
