import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  ivrMessagesSelector,
  setSelectedVoicemailMailbox,
  setVoicemailSearchText,
} from './voicemailSlice';
import IvrMessageSort from './IvrMessageSort';
import { getMailboxDropdownOption } from '../../../utils/ivrMessage';

const PREFIX = 'IvrMessageFilter';

export default function IvrMessageFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [selectedMailbox, setSelectedMailbox] = useState('all');
  const [mailBoxes, setMailBoxes] = useState([]);
  const ivrMessages = useSelector(ivrMessagesSelector);

  useEffect(() => {
    if (ivrMessages && Array.isArray(ivrMessages) && ivrMessages.length > 0) {
      const mBoxes = Array.from(new Set(ivrMessages.map((i) => getMailboxDropdownOption(i))));
      setMailBoxes(mBoxes);
      // if there is just one mBox, we select that by default, else 'all' is default value
      if (mBoxes && Array.isArray(mBoxes)) {
        if (mBoxes?.length === 0) {
          setMailBoxes([]);
        } else if (mBoxes?.length === 1) {
          setSelectedMailbox(mBoxes[0]);
        } else {
          setSelectedMailbox('all');
        }
      }
    } else {
      setMailBoxes([]);
    }
  }, [ivrMessages]);

  useEffect(() => {
    dispatch(setSelectedVoicemailMailbox(selectedMailbox));
  }, [selectedMailbox]);

  useEffect(() => {
    dispatch(setVoicemailSearchText(searchText));
  }, [searchText]);

  const handleSearchTextChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSearchText(value);
    } else {
      setSearchText('');
    }
  };

  const handleMailboxChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSelectedMailbox(value);
    } else {
      setSelectedMailbox('all');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 5,
        paddingTop: '0',
        marginBottom: '20px',
      }}
    >
      <FormControl size="small">
        <TextField
          fullWidth
          id="voicemail-text-search"
          label="Search by transcription text"
          onChange={handleSearchTextChange}
          size="small"
          variant="filled"
        />
      </FormControl>
      {mailBoxes && Array.isArray(mailBoxes) && mailBoxes?.length > 0 && (
        <FormControl size="small" sx={{ minWidth: '200px' }} variant="filled">
          <InputLabel id="select-mailbox">Select Mailbox</InputLabel>
          <Select
            id="linenumber-mailbox"
            label="Select Mailbox Number"
            labelId="select-mailbox"
            onChange={handleMailboxChange}
            value={selectedMailbox}
          >
            {mailBoxes?.length > 1 && <MenuItem value="all">All</MenuItem>}
            {mailBoxes?.map((mBox) => {
              return (
                <MenuItem key={mBox} value={mBox}>
                  {mBox}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      <IvrMessageSort />
    </Box>
  );
}
