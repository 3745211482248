import { useEffect, useState } from 'react';
// import "fast-text-encoding"; // TODO: review it's need
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Input,
  IconButton,
  InputAdornment,
  Button,
  FormControl,
  TextField,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-virtualized/styles.css';
import ClearIcon from '@mui/icons-material/Clear';
import {
  lastMsgQuerySelector,
  deliveryContactsSelector,
  getMessagesByContact,
  getMessagesForUser,
  messagesSelector,
  selReceiverSelector,
  selRecFeaturesSelector,
  queryNoteAuthorSelector,
  setQueryNoteAuthor,
  setQueryPattern,
  setQueryPatternCase,
  setLiveUpdates as setLiveUpdatesAction,
  setEscalationMessageQuery,
} from './messageSlice';
import { portalUserSelector, timezoneSelector } from '../../app/authSlice';
import { PatternCaseType } from '../../utils/message';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';

const PREFIX = 'MessageQueryDialog';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

export default function MessageQueryDialog({
  type,
  openDialog,
  fetchMessages,
  unSetFetchMessages,
  propagateOnClose,
}) {
  const messages = useSelector(messagesSelector);
  const dispatch = useDispatch();
  const selReceiver = useSelector(selReceiverSelector);
  const disabledFeatures = useSelector(selRecFeaturesSelector);
  const deliveryContacts = useSelector(deliveryContactsSelector);
  const [pattern, setPattern] = useState('');
  const noteAuthor = useSelector(queryNoteAuthorSelector);
  const [searchType, setSearchType] = useState(
    type === 'contact' ? 'Recent (last 1 day)' : 'Recent (last 7 days)',
  );
  const [messageLimit, setMessageLimit] = useState(50);
  const [sequenceNumber, setSequenceNumber] = useState('');
  const [escalationType, setEscalationType] = useState('Retrieve All Messages');
  const [followup, setFollowup] = useState('Retrieve All Messages');
  const [liveUpdates, setLiveUpdates] = useState('No');
  const [secure, setSecure] = useState('All Messages');
  const [patternCase, setPatternCase] = useState(PatternCaseType.ignoreCase);
  const defaultTimezone = useSelector(timezoneSelector);
  const [selectedDate, setSelectedDate] = useState(
    moment().tz(defaultTimezone).startOf('day').format('YYYY-MM-DDTHH:mm'),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().tz(defaultTimezone).endOf('day').format('YYYY-MM-DDTHH:mm'),
  );
  const [starttimeFilterError, setStarttimeFilterError] = useState(false);
  const [starttimeFilterErrorText, setStarttimeFilterErrorText] = useState(
    'Start Date is greater than End date',
  );
  const [endtimeFilterError, setEndtimeFilterError] = useState(false);
  const [endtimeFilterErrorText, setEndtimeFilterErrorText] = useState(
    'End Date is less than Start date',
  );

  const [archive, setArchive] = useState('No');

  const lastMsgQuery = useSelector(lastMsgQuerySelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const dateOpen = Boolean(anchorEl);
  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState([]);
  const portalUser = useSelector(portalUserSelector);

  useEffect(() => {
    if (fetchMessages) {
      handleSave();
      unSetFetchMessages();
    }
  }, [fetchMessages]);

  useEffect(() => {
    if (messages) {
      setCount({ prev: 0, next: 10 });
      setCurrent(messages.slice(0, 10));
      setHasMore(true);
    }
  }, [messages, selReceiver]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (lastMsgQuery === 'reload') {
      handleSave();
    }
  }, [lastMsgQuery]);

  useEffect(() => {
    if (portalUser && portalUser.id) {
      setLiveUpdates(portalUser.liveUpdates ? 'Yes' : 'No');
      dispatch(setLiveUpdatesAction(portalUser.liveUpdates));
    }
  }, [portalUser]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'pattern':
        if (event.target.value && event.target.value.trim().length === 0) {
          setPattern('');
          dispatch(setQueryPattern(''));
        } else {
          setPattern(event.target.value);
          dispatch(setQueryPattern(event.target.value));
        }
        break;
      case 'searchType':
        setSearchType(event.target.value);
        break;
      case 'sequenceNumber':
        setSequenceNumber(event.target.value);
        break;
      case 'escalationType':
        setEscalationType(event.target.value);
        dispatch(
          setEscalationMessageQuery(event.target.value === 'Retrieve Only Pending Escalations'),
        );
        break;
      case 'archive':
        setArchive(event.target.value);
        break;
      case 'followup':
        setFollowup(event.target.value);
        break;
      case 'liveUpdate':
        setLiveUpdates(event.target.value);
        dispatch(setLiveUpdatesAction(event.target.value === 'Yes'));
        break;
      case 'secure':
        setSecure(event.target.value);
        break;
      case 'patternCase':
        setPatternCase(event.target.value);
        dispatch(setQueryPatternCase(event.target.value));
        break;
      case 'messageLimit':
        setMessageLimit(event.target.value);
        break;
      case 'noteAuthor':
        dispatch(setQueryNoteAuthor(event.target.value));
        break;
      default:
        break;
    }
  };

  const resetPattern = () => {
    setPattern('');
    dispatch(setQueryPattern(''));
  };

  const resetNoteAuthor = () => {
    dispatch(setQueryNoteAuthor(''));
  };

  const handleDateChange = (event) => {
    switch (event.target.name) {
      case 'startDate':
        if (moment(event.target.value).valueOf() - moment(selectedEndDate).valueOf() > 0) {
          setStarttimeFilterError(true);
        } else {
          setStarttimeFilterError(false);
        }
        setEndtimeFilterError(false);
        setSelectedDate(event.target.value);
        break;
      case 'endDate':
        if (moment(event.target.value).valueOf() - moment(selectedDate).valueOf() < 0) {
          setEndtimeFilterError(true);
        } else {
          setEndtimeFilterError(false);
        }
        setStarttimeFilterError(false);
        setSelectedEndDate(event.target.value);
        break;
      default:
    }
  };

  const handleClose = () => {
    propagateOnClose();
  };

  const handleSave = () => {
    var query = 'pattern=&patternCase=&';
    if (pattern.trim().length > 0) {
      query = `pattern=${encodeURIComponent(pattern)}&patternCase=${patternCase}&`;
    }
    switch (searchType) {
      case 'Recent (last 31 days)':
        query += `queryTimeLimit=45000&messageLimit=${messageLimit}&secureOnly=${
          secure === 'Secure Messages Only'
        }&pendingEscalationsOnly=${
          escalationType === 'Retrieve Only Pending Escalations'
        }&followupRequiredOnly=${followup === 'Retrieve Only Followup Required'}&startDate=${moment
          .utc()
          .subtract(30, 'd')
          .format('YYYYMMDDHHmmss')}&endDate=${moment
          .utc()
          .format('YYYYMMDDHHmmss')}&archived=${archive.charAt(0)}`;
        break;
      case 'Recent (last 7 days)':
        query += `queryTimeLimit=45000&messageLimit=${messageLimit}&secureOnly=${
          secure === 'Secure Messages Only'
        }&pendingEscalationsOnly=${
          escalationType === 'Retrieve Only Pending Escalations'
        }&followupRequiredOnly=${followup === 'Retrieve Only Followup Required'}&startDate=${moment
          .utc()
          .subtract(7, 'd')
          .format('YYYYMMDDHHmmss')}&endDate=${moment
          .utc()
          .format('YYYYMMDDHHmmss')}&archived=${archive.charAt(0)}`;
        break;
      case 'Recent (last 1 day)':
        query += `queryTimeLimit=45000&messageLimit=${messageLimit}&secureOnly=${
          secure === 'Secure Messages Only'
        }&pendingEscalationsOnly=${
          escalationType === 'Retrieve Only Pending Escalations'
        }&followupRequiredOnly=${followup === 'Retrieve Only Followup Required'}&startDate=${moment
          .utc()
          .subtract(1, 'd')
          .format('YYYYMMDDHHmmss')}&endDate=${moment
          .utc()
          .format('YYYYMMDDHHmmss')}&archived=${archive.charAt(0)}`;
        break;
      case 'Sequence Number':
        query += `queryTimeLimit=45000&messageLimit=300&secureOnly=${
          secure === 'Secure Messages Only'
        }&pendingEscalationsOnly=${
          escalationType === 'Retrieve Only Pending Escalations'
        }&followupRequiredOnly=${followup === 'Retrieve Only Followup Required'}&sequenceNumber=${
          sequenceNumber
        }&startDate=${moment.utc().subtract(30, 'd').format('YYYYMMDDHHmmss')}&endDate=${moment
          .utc()
          .format('YYYYMMDDHHmmss')}&archived=${archive.charAt(0)}`;
        break;
      case 'Date':
        query += `queryTimeLimit=45000&messageLimit=&secureOnly=${
          secure === 'Secure Messages Only'
        }&pendingEscalationsOnly=${
          escalationType === 'Retrieve Only Pending Escalations'
        }&followupRequiredOnly=${followup === 'Retrieve Only Followup Required'}&startDate=${moment
          .tz(selectedDate, 'YYYY-MM-DDTHH:mm', defaultTimezone)
          .tz('America/New_York')
          .format('YYYYMMDDHHmmss')}&endDate=${moment
          .tz(selectedEndDate, 'YYYY-MM-DDTHH:mm', defaultTimezone)
          .tz('America/New_York')
          .format('YYYYMMDDHHmmss')}&archived=${archive.charAt(0)}`;
        break;
      default:
    }

    if (type === 'contact') {
      if (deliveryContacts && deliveryContacts.length == 1) {
        dispatch(getMessagesByContact(deliveryContacts[0].CONTACT_ID, '', '', query));
      }
    } else {
      dispatch(getMessagesForUser(selReceiver, '', '', query));
    }
    handleClose();
  };

  useEffect(() => {
    if (selReceiver !== 0) {
      handleSave();
    }
  }, [selReceiver]);

  return (
    <StyledDialog
      aria-labelledby="simple-dialog-title"
      className={classes.root}
      data-tour="rtQueryMsg-parent"
      disableBackdropClick
      maxWidth="md"
      onClose={handleClose}
      open={openDialog}
    >
      <DialogTitle id="simple-dialog-title"> {t('Change Message Query')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" />
        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="search-label">{t('Search Type')}</InputLabel>
          <Select
            id="search"
            labelId="search-label"
            name="searchType"
            onChange={handleChange}
            value={searchType}
          >
            {(type === 'contact'
              ? ['Recent (last 1 day)', 'Date']
              : ['Recent (last 7 days)', 'Recent (last 31 days)', 'Sequence Number', 'Date']
            ).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {searchType === 'Sequence Number' && (
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              fullWidth
              label={t('Sequence Number')}
              margin="dense"
              name="sequenceNumber"
              onChange={handleChange}
              type="text"
              value={sequenceNumber}
            />
          </FormControl>
        )}

        {searchType === 'Date' && (
          <FormControl className={classes.formControl}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              defaultValue={selectedDate}
              error={starttimeFilterError}
              helperText={starttimeFilterError && starttimeFilterErrorText}
              id="datetime-local"
              label="Start Time"
              name="startDate"
              onChange={handleDateChange}
              type="datetime-local"
              value={selectedDate}
              variant="standard"
            />

            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              defaultValue={selectedEndDate}
              error={endtimeFilterError}
              helperText={endtimeFilterError && endtimeFilterErrorText}
              id="datetime-local2"
              label="End Time"
              name="endDate"
              onChange={handleDateChange}
              type="datetime-local"
              value={selectedEndDate}
              variant="standard"
            />
          </FormControl>
        )}

        {(searchType === 'Recent (last 31 days)' || searchType === 'Recent (last 7 days)') && (
          <FormControl className={classes.formControl} fullWidth variant="standard">
            <InputLabel id="messageLimit-label">{t('Message Limit')}</InputLabel>
            <Select
              id="messageLimit"
              labelId="messageLimit-label"
              name="messageLimit"
              onChange={handleChange}
              value={messageLimit}
            >
              {[10, 25, 50, 100, 200].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="escalationType-label">{t('Escalations')}</InputLabel>
          <Select
            id="escalationType"
            labelId="escalationType-label"
            name="escalationType"
            onChange={handleChange}
            value={escalationType}
          >
            {['Retrieve All Messages', 'Retrieve Only Pending Escalations'].map((value) => (
              <MenuItem key={value} value={value}>
                {t(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="followup-label">{t('Followup')}</InputLabel>
          <Select
            id="followup"
            labelId="followup-label"
            name="followup"
            onChange={handleChange}
            value={followup}
          >
            {['Retrieve All Messages', 'Retrieve Only Followup Required'].map((value) => (
              <MenuItem key={value} value={value}>
                {t(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="secure-label">{t('Secure')}</InputLabel>
          <Select
            id="secure"
            labelId="secure-label"
            name="secure"
            onChange={handleChange}
            value={secure}
          >
            {['All Messages', 'Secure Messages Only'].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {disabledFeatures.indexOf('FUNERAL') === -1 && (
          <FormControl className={classes.formControl} fullWidth variant="standard">
            <InputLabel id="archived-label">{t('Show Archived')}</InputLabel>
            <Select
              id="archive"
              labelId="archived-label"
              name="archive"
              onChange={handleChange}
              value={archive}
            >
              {['Yes', 'No'].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel htmlFor="">{t('Message Pattern')}</InputLabel>
          <Input
            endAdornment={
              <InputAdornment position="end">
                <PortalIconButton aria-label="reset" onClick={resetPattern}>
                  <ClearIcon />
                </PortalIconButton>
              </InputAdornment>
            }
            fullWidth
            margin="dense"
            name="pattern"
            onChange={handleChange}
            type="text"
            value={pattern}
          />
        </FormControl>

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel htmlFor="">{t('Note Author')}</InputLabel>
          <Input
            endAdornment={
              <InputAdornment position="end">
                <PortalIconButton aria-label="reset" onClick={resetNoteAuthor}>
                  <ClearIcon />
                </PortalIconButton>
              </InputAdornment>
            }
            fullWidth
            margin="dense"
            name="noteAuthor"
            onChange={handleChange}
            type="text"
            value={noteAuthor}
          />
        </FormControl>

        <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="patternCase-label">{t('Case')}</InputLabel>
          <Select
            id="patternCase"
            labelId="patternCase-label"
            name="patternCase"
            onChange={handleChange}
            value={patternCase}
          >
            {[PatternCaseType.ignoreCase, PatternCaseType.matchCase].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl className={classes.formControl} fullWidth variant="standard">
          <InputLabel id="patternCase-label">{t('Live Updates')}</InputLabel>
          <Select
            id="patternCase"
            labelId="patternCase-label"
            name="liveUpdate"
            onChange={handleChange}
            value={liveUpdates}
          >
            {['No', 'Yes'].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </DialogContent>
      <DialogActions>
        {searchType === 'Date' && !starttimeFilterError && !endtimeFilterError && (
          <PortalButton color="primary" onClick={handleSave} variant="contained">
            {t('Query')}
          </PortalButton>
        )}
        {searchType !== 'Date' && (
          <PortalButton color="primary" onClick={handleSave} variant="contained">
            {t('Query')}
          </PortalButton>
        )}
        <PortalButton color="secondary" onClick={handleClose} variant="contained">
          {t('Cancel')}
        </PortalButton>
      </DialogActions>
    </StyledDialog>
  );
}
