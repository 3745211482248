// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyByA9tSpESY2Tu7HGU1KiGwofd7Y8__9nU',
  authDomain: 'map-customer-portal.firebaseapp.com',
  databaseURL: 'https://map-customer-portal-default-rtdb.firebaseio.com',
  projectId: 'map-customer-portal',
  storageBucket: 'map-customer-portal.appspot.com',
  messagingSenderId: '989136367781',
  appId: '1:989136367781:web:2f7601cfda1f796ff51d4c',
  measurementId: 'G-1Y4W757E44',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseApp);

// Export the initialized app and database
const database = getDatabase(firebaseApp);
export { firebaseApp, database, auth };
